import React from "react"
import { useEffect } from "react"

export default function HubSpotForm() {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "7041207",
          formId: "5ca9d578-8735-4fec-ae2b-e8f87e81efd7",
          target: "#hubspotForm",
        })
      }
    })
  }, [])

  return (
    <div
      id="hubspotForm"
      className="hubspotForm"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    ></div>
  )
}
